import './Menu.css'
export default function Menu() {
    return (
      <div className="menu">
        <div className="menu__inner">
            <ul className="menu__list">
                <li className="menu__list-item">
                    <a href="https://google.nl" className="menu__list-item-link">
                        Home
                    </a>
                </li>
                <li className="menu__list-item">
                    <a href="https://google.nl" className="menu__list-item-link menu__list-item-link--active">
                        Join a Party
                    </a>
                </li>
                <li className="menu__list-item">
                    <a href="https://google.nl" className="menu__list-item-link">
                        How It Works
                    </a>
                </li>
                <li className="menu__list-item">
                    <a href="https://google.nl" className="menu__list-item-link">
                        Advantages
                    </a>
                </li>
                <li className="menu__list-item">
                    <a href="https://google.nl" className="menu__list-item-link">
                        FAQ
                    </a>
                </li>
                <li className="menu__list-item">
                    <a href="https://google.nl" className="menu__list-item-link">
                        Tips
                    </a>
                </li>
                <li className="menu__list-item">
                    {/* Import button component here */}
                </li>
            </ul>
        </div>
      </div>
    )
}