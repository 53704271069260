export default function IconApple() {
    return (
        <svg width="27" height="31" viewBox="0 0 27 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#apple0)">
                <path d="M21.8578 28.9062C20.2318 30.4842 18.4378 30.2382 16.7278 29.4942C14.9098 28.7352 13.2478 28.6872 11.3278 29.4942C8.93681 30.5262 7.66781 30.2262 6.22781 28.9062C-1.90219 20.5362 -0.702187 7.78623 8.53781 7.30623C10.7788 7.42623 12.3478 8.54523 13.6678 8.63823C15.6298 8.23923 17.5078 7.09623 19.6078 7.24623C22.1308 7.45023 24.0178 8.44623 25.2778 10.2372C20.0878 13.3572 21.3178 20.1972 26.0848 22.1172C25.1308 24.6222 23.9068 27.0972 21.8548 28.9272L21.8578 28.9062ZM13.4878 7.21623C13.2448 3.49623 16.2598 0.43623 19.7278 0.13623C20.2048 4.42623 15.8278 7.63623 13.4878 7.21623Z" fill="black"/>
            </g>
            <defs>
                <clipPath id="apple0">
                    <rect width="25.26" height="30" fill="white" transform="translate(0.797363 0.104004)"/>
                </clipPath>
            </defs>
        </svg>
    )
}