export default function IconFacebook() {
    return (
        <svg width="15" height="28" viewBox="0 0 15 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#facebook0)">
                <rect width="14" height="26.9646" transform="translate(0.0385742 0.416992)" fill="white"/>
                <path d="M9.12569 27.382V15.085H13.2533L13.8713 10.2927H9.12569V7.23292C9.12569 5.84545 9.51103 4.89982 11.5008 4.89982L14.0386 4.89865V0.61246C13.5995 0.554269 12.0932 0.423828 10.3407 0.423828C6.68181 0.423828 4.17688 2.65707 4.17688 6.75854V10.2928H0.0385742V15.0851H4.17675V27.3821L9.12569 27.382Z" fill="#3B5998"/>
            </g>
            <defs>
                <clipPath id="facebook0">
                    <rect width="14" height="26.9646" fill="white" transform="translate(0.0385742 0.416992)"/>
                </clipPath>
            </defs>
        </svg>
    )
}