import './Footer.css'

import {BrowserRouter as Router, Switch, Route }  from 'react-router-dom';
export default function Footer() {

  if (window.location.pathname === '/QRScanner') return null;
  return(
    <div className="footer">
      <div className="footer__inner">
        <p>Copyright {new Date().getFullYear()} | PartyMeister.App</p>
      </div>
    </div>
  )
}
