import './Button.css'
export default function Button(props) {
    return(
      <button
        onClick={props.handler}
        className={`button${props.class ? ' ' + props.class : ''}`}
      >
        {props.label}
      </button>
    )
}