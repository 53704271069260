import './Main.css'

import {BrowserRouter as Router, Switch, Route }  from 'react-router-dom';
function Main(props) {
    if (window.location.pathname === '/QRScanner') {
      return (
        <div className="main main__full_screen">
          {props.children}
        </div>
      )
    };
    return (
      <div className="main">
        {props.children}
      </div>
    );
}
export default Main;
