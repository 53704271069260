import url from 'url'

import Image from '../../components/Image/Image'
import Button from '../../components/Button/Button'
import './ScanQr.css'

import ImageQr from '../../assets/images/prt_qr_example.jpg'

import {useEffect, useState } from 'react'
import {useLocation, Redirect, useHistory} from 'react-router-dom'

import Login from '../Login/Login'
import QRScanner from '../QRScanner/QRScanner'
import {httpGetAuth, httpPostAuth, httpPutAuth, httpDeleteAuth} from '../../common/HTTP'
import Alert from '../../components/Alert/Alert'
import {fatal_log, error_log, warn_log, info_log, debug_log, setPartyForLogging, setUserForLogging} from '../../common/Logger'
export default function ScanQr() {

    const history = useHistory();
    //const [party_id, setParty] = useState(null);
    const [input, setInput] = useState({state: 'scan-page', party_id: null});
    
    info_log('Refresh ScanQRCode screen==============' + window.sessionStorage.getItem('party_id'));
    
    const query = new URLSearchParams(useLocation().search);
    
    setPartyForLogging("");
    
    var party = query.get('party');
    var isLoggedIn = false;
    if (party == null) {
      party = window.sessionStorage.getItem('party_id');
      
      if (party != null) isLoggedIn = true;
    }
    //set party id to be logged with each log
    if (party != null) setPartyForLogging(party);
    //set user id to be logged with each log
    if (!isLoggedIn)
      setUserForLogging("");
      
    debug_log('ScanQr: party id: ' + party + ' state:'  + input.state);
    
    useEffect(() => {
      info_log('########################useEffect ScanQr');
      async function getPartyDetails() {
        if (party != null) 
        {
            try {
              const result = await httpGetAuth('/getpartystatus', {party_id: party}, true);
              
               if (!result.data || !result.data.hasOwnProperty('p_status')) {
                //alert('Party not found!');
                //setInput(null);
                error_log('result is not proper');
                setInput({state:'error-state', party_id: null});
              }
              else if (result.data && result.data.p_status == "STARTED") {
                if (result.data.guests_limit > -1 && result.data.guest_count >= result.data.guests_limit)
                  setInput({state:'guests-limit-reached', party_id: party});
                else 
                  setInput({state:'login-page', party_id: party});
              }
              else if (result.data && result.data.p_status == "ENDED") {
                setInput({state:'party-ended', party_id: null});
              }
              else {
                error_log('result is not proper, else');
                setInput({state:'error-state', party_id: null});

              }     
          } catch(err) {
              error_log('getpartystatus error: ' + err);
              setInput({state:'error-state', party_id: null});
          }
        }
      }
      //we are checking party details only when user is not logged in. when this comes after refresh from login we need to immediatly go to welcome Screen
      //else login screen comes after welcome screen (due to async nature of getPartyDetails function)
      if (!isLoggedIn) 
         getPartyDetails();
      else setInput({state:'login-page', party_id: party});
    }, []);
    
    // 
    // const [input, setInput] = useState({state: 'start', party_id: party});
    // 
    // debug_log('party_id:' + input.party_id + '     ScanQR state:' + input.state);
    // 
    // if (input.party_id !== null && input.state !== 'qr-result')
    // {
    //   setInput({
    //     party_id: input.party_id,
    //     state: 'qr-result'
    //   });
    // }
    
    const text = [
        { title: 'Please scan QR code to join party' },
        { label: 'Scan QR Code' },
        { paragraph: 'Download the PartyMeister app for a better experience' }
    ]

    function buttonHandler() {
        info_log('button clicked');
          setInput({state:'qr-scanner-page', party_id: null});
        // history.push("/QRScanner");
        // setInput({
        //   party_id: input.party_id,
        //   state: 'scan-qr'
        // });
    }
    
    // 
    // function qrcodeHandler(data) {
    //   //debug_log('qrcodeHandler');
    //     if (data) {
    //     //debug_log('qr code url: ' + data.text);
    // 
    //     const queryObject = url.parse(data.text,true).query;
    //     debug_log('query obj:' + JSON.stringify(queryObject));
    // 
    //     if (queryObject.hasOwnProperty('party')) {
    //       debug_log('party============================:' + queryObject.party);
    //       window.sessionStorage.setItem('party_id', queryObject.party);
    //       // setState('qr-result');
    //       setInput({
    //               state: 'qr-result',
    //               party_id: queryObject.party
    //           });
    // 
    //     }
    // 
    //   }
    // }
    // 
    // function handleError(err) {
    //   debug_log(err);
    // }
    // debug_log('party var:' + input.party_id);
    
    // if (state === 'qr-result') {
    //   debug_log('Login Screen');
    //   return <Login party_id={party_id}/>
    // }
      // debug_log('line 62: ' + input.state);
    return(
    <>
    {input.state === 'qr-scanner-page' && 
      <>
        return <Redirect to={{
              pathname: '/QRScanner'
          }}
        />
      </>
    }
    
    {input.state === 'login-page' && 
      <>
        return <Redirect to={{
              pathname: '/Login',
              state: { party_id: input.party_id }
          }}
        />
      </>
    }
    {input.state === 'scan-page' &&  (
      <div className="scanQr">
            <div className="scanQr__inner">
                <h2 className="scanQr__title">{text[0].title}</h2>
                <Image
                    src={ImageQr}
                    alt="PartyMeister QR Example"
                    class="image__iphone"
                />
                <Button
                    handler={buttonHandler}
                    class="button__text button__primary"
                    label={text[1].label}
                />
                <p className="scanQr__download">{text[2].paragraph}</p>
            </div>
        </div>
      )}
      
      {input.state === 'party-ended' && 
        <>
          return <Alert
            title="Party Ended"
            body="Sorry this party seems to be Ended!"
            handlerFirst={() => { 
              query.delete('party')
              history.replace({
                search: query.toString(),
              });
              setInput({state:'scan-page', party_id: null});
             }
           }
            labelFirst="OK"
          />
        </>
      }
      
      {input.state === 'guests-limit-reached' && 
        <>
          return <Alert
            title="Oops...Limit Reached!"
            body="Sorry, your party seems to have hit the maximum guest limit. Please contact party host to upgrade for unlimited guests."
            handlerFirst={() => { 
              query.delete('party')
              history.replace({
                search: query.toString(),
              });
              setInput({state:'scan-page', party_id: null});
             }
           }
            labelFirst="OK"
          />
        </>
      }
      
      {input.state === 'error-state' && 
        <>
          return <Alert
            title="Can't connect to Party"
            body="Oops..We can't find a party with the QR Code."
            handlerFirst={() => { 
              query.delete('party')
              history.replace({
                search: query.toString(),
              });
              setInput({state:'scan-page', party_id: null});
             }
           }
            labelFirst="OK"
          />
        </>
      }
    </>
    )
}