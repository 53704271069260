
import React, { useEffect, useState } from 'react'
import {useLocation, Redirect} from "react-router-dom"
import Amplify, { API, Auth, Hub , Storage} from 'aws-amplify'
import awsconfig_dev_eu_west_1 from '../../aws-exports-dev-eu-west-1'
import awsconfig_dev_us_east_1 from '../../aws-exports-dev-us-east-1'
import awsconfig_prod_us_east_1 from '../../aws-exports-prod-us-east-1'

//import AWS from 'aws-sdk'
import ImageUpload from "../../data/fileUpload"
// import axios from 'axios'
import moment from 'moment'
// import Crypto, { sign } from 'crypto'
// import AWSSignature from 'react-native-aws-signature'
import './Login.css'
import {httpGetAuth, httpPostAuth, httpPutAuth} from '../../common/HTTP'

// View
import Welcome from '../Welcome/Welcome'

// Components
import Image from '../../components/Image/Image'
import Button from '../../components/Button/Button'

// Assets
import IconGoogle from '../../assets/icons/IconGoogle/IconGoogle'
import IconFacebook from '../../assets/icons/IconFacebook/IconFacebook'
import IconApple from '../../assets/icons/IconApple/IconApple'
import ImageLogin from '../../assets/images/prt_login_img.jpg'

import {useHistory} from 'react-router-dom'
import {fatal_log, error_log, warn_log, info_log, debug_log, setUserForLogging} from '../../common/Logger'

var resultQuery = "";

var awsconfignew = awsconfig_prod_us_east_1;

if (process.env.REACT_APP_REGION == "us-east-1")
{
  if (process.env.REACT_APP_BUILD == "prod") {
    awsconfignew = awsconfig_prod_us_east_1;
    awsconfignew.oauth.domain = "auth.partymeister.tv";
    awsconfignew.oauth.redirectSignIn = "https://webapp.partymeister.tv/";
    awsconfignew.oauth.redirectSignOut = "https://webapp.partymeister.tv/";
    //amplify configured url's
    // awsconfignew.oauth.redirectSignIn = "https://prod.dhdpr8jcye3bk.amplifyapp.com/";
    //   awsconfignew.oauth.redirectSignOut = "https://prod.dhdpr8jcye3bk.amplifyapp.com/";
  }
  else {
    awsconfignew = awsconfig_dev_us_east_1;
    awsconfignew.oauth.redirectSignIn = "https://dev.dhdpr8jcye3bk.amplifyapp.com/";
    awsconfignew.oauth.redirectSignOut = "https://dev.dhdpr8jcye3bk.amplifyapp.com/";
  }
}
else
{
  awsconfignew = awsconfig_dev_eu_west_1;
  awsconfignew.oauth.redirectSignIn = "https://dev.d3312yzg464xzw.amplifyapp.com/";
  awsconfignew.oauth.redirectSignOut = "https://dev.d3312yzg464xzw.amplifyapp.com/";
}

//For testing
// awsconfignew.oauth.redirectSignIn = "http://localhost:3000/";
// awsconfignew.oauth.redirectSignOut = "http://localhost:3000/";

console.log(awsconfignew);
Amplify.configure(awsconfignew);

var albumBucketName = "partymeister";
var bucketRegion = "eu-west-1";
var party = null;//window.sessionStorage.getItem('party');
var user_id = null;
var fileToUpload;

async function uploadFile(fileToUpload, sub, user_name)
{
  debug_log('uploadFile' + sub + user_name);
  var fileName = fileToUpload.name;

  var time = moment().utc().format("YYYYMMDDHHmmss");
  fileName = time + '_' + sub + '_'+  fileName;

  var s3_filename = "org/" + party + "/"  + fileName;

  try {
    var result = await Storage.put(s3_filename, fileToUpload, {
      level: 'public',
      contentType: 'image/jpeg'
    });

    debug_log(result);
    debug_log('user name:' + user_name);

    const resp = await httpPutAuth('/uploadfile', {party_id: party, file_id: fileName, user_id: sub, user_name: user_name}, false);
    debug_log('!!!!!!!!!!!!!!!!!!!' + '#####' + JSON.stringify(resp));

    return resp;
  }
  catch(err) {
  //TODO: clean up if something fails
  error_log('failed to upload' + err);
    return err;
  }
}

function Login(data) {
  //User Agent
  const ua = navigator.userAgent.toLowerCase();
  const android = ua.indexOf("android") > -1;

  const history = useHistory();
  const [user, setUser] = useState(null);
  const [outputStr, setOutput] = useState('');
  const [moreLogin, setMoreLogin] = useState(false);

  const toggle = () => setMoreLogin(!moreLogin);

  useEffect(() => {
    info_log('########################useEffect');
    onLoad();
    return function cleanup() {
        Hub.remove('auth', authListener);
    }
  }, []);
    
    try {
      party = data.history.location.state.party_id;
      // party = window.sessionStorage.getItem('party_id');
  } catch(err) {
  }
  
  if (party == null) {
    error_log('party id not set');
    return <Redirect to='/' />
  }
  
  const text = [
    { title: 'Please login to continue' },
    { label: 'Logout' }
  ]

  info_log('Login.js loading =========================party_id:' + party);

  // const pid = GetURLParameter('party');
  // if (pid !== undefined) {
  //   debug_log('party:' + pid);
  //   party = pid;
  //   window.sessionStorage.setItem('party', pid);
  // }
var resp = {status: true, msg: ""};
  async function authListener({ payload: { event, data } })
  {
    
    debug_log('event: ' + event);
    //debug_log('data: ' + JSON.stringify(data));
    switch (event) {
      case 'signIn':
      // case 'cognitoHostedUI':          
      info_log('signin ');
        //check joinstatus
        if (resp.status == true) {
          
          //only fetch user details
          var userData = await getUser();
          
          setUserForLogging(userData.username);
          //check if join party is success then set state user (so that screen move to welcom) else show alert and signout.(signout redirect will handle rest.)
            await joinPartyHandler(0, resp);
            
            if (resp.status == false) { //if join party is failed for any reason, show alert and signout.
              //We need to remove party id as generally it is removed in welcome screeen but here we are not moving to welcome screen rather to scan qr screen
              window.sessionStorage.removeItem('party_id');
              // No need to show alert as this condition generally will not be met (because we check party status in qr scan screens).//anyways we are signing out so
              //we will move to scan screen if party is ended while logging in.
              //alert(resp.msg);
              error_log('joining failed:' + resp.message);
              Auth.signOut({ global: true });
            } else { //set user state only when join party is success
              user_id = userData.username;
              setUser(userData);
            }
        }
        
        break;
      case 'signOut':
        info_log('signout');
        setUser(null);
        break;
      case 'signIn_failure':
      case 'cognitoHostedUI_failure':
        error_log('Sign in failure', data);
        break;
      case 'tokenRefresh':
        debug_log('!!!!!!!!!!!!!!!!!! ' + JSON.stringify(data));
      break;
      case "customOAuthState":
        debug_log('customState:' + data);
        //party = data;
        break;
        default:
          break;
    }
  }
  

  async function onLoad() {

    //Hub.listen('auth', ({ payload: { event, data } }) => );
    
    Hub.listen('auth', authListener);
  }

  function setFile(file) {
    debug_log('fileToUpload:' + file);
     fileToUpload = file;
  }


  function getFile() {
    return fileToUpload;
  }
  
  async function UploadImage() {
    const file = getFile();
    debug_log('fileToUpload: ' + file);
    
    if (file && file.name !== "")
    {
      var user = await getUser();
      debug_log('@@@@@@@@@@' + JSON.stringify(user));
      var res = await uploadFile(file, user.username, user.attributes.given_name);
      setOutput(res);
    }
  }

  async function getUser() {
    const session = await Auth.currentSession();
    //debug_log('@@@@: ' + session.accessToken.jwtToken);
    try {
      const userData = await Auth.currentAuthenticatedUser();
      //debug_log(userData);
      return userData;
    } catch (e) {
      error_log('Not signed in' + e);
      return null;
    }
  }

async function joinPartyHandler(is_host, resp)
{
  if (party == null) {
    error_log('party is null');
    resp.status = false;
    resp.message = "Unknown Party";
    return;
  }
	info_log('joinparty');
    try {
      const userData = await Auth.currentUserInfo();
      debug_log(userData.username);
      var profile_url = '';
      var first_name = '';
      var last_name = '';
      var email = '';
      var cognito_id = '';

      // debug_log('######@' + JSON.stringify(userData));

      if (userData.username.startsWith('google')) {

        cognito_id = userData.username;
        profile_url = userData.attributes.picture;
        first_name = userData.attributes.name;
        last_name = userData.attributes.family_name;
        email = userData.attributes.email;

      }
      else if (userData.username.startsWith('facebook'))
      {
        var userIdIndex = userData.username.indexOf("_");
        var userId = userData.username.substr(userIdIndex+1);
        cognito_id = userData.username;
        profile_url = ' https://graph.facebook.com/'+ userId +'/picture?type=normal&redirect=true&access_token=' + userData.attributes.profile;
        debug_log(profile_url);
        first_name = userData.attributes.name;
        last_name = userData.attributes.family_name;
        email = userData.attributes.email;
      }
      else if (userData.username.startsWith('signinwithapple'))
      {
        cognito_id = userData.username;
        first_name = userData.attributes.given_name;
        last_name = userData.attributes.family_name;
        email = userData.attributes.email;
      }

    const resp = await httpPutAuth('/joinparty', 
                  { party_id: party, 
                    user_id: userData.username, 
                    provider_id: cognito_id,
                    first_name : first_name,
                    last_name : last_name,
                    user_email : email,
                    is_host : is_host,
                    'profile_icon_url': profile_url}, false);

    } catch(err) {
      resp.status = false;
      
      debug_log(err.response.data);
      if (err.response.data.hasOwnProperty('message') && err.response.data.message == "PARTY_ENDED")
        resp.message = "Can't join Party is Already Ended!";
      else if (err.response.data.hasOwnProperty('message') && err.response.data.message == "ALREADY_JOINED") {
          resp.status = true;
          resp.message = "You have already joined the Party!";
        }
      else {
        error_log('Cant Join! Unknown Error');
        resp.message = "Can't Join! Unknown Error";
      }
      
      return;
    };
}

  if (user) 
  {
       return (<Redirect to={{
          pathname: '/Welcome',
          state: { party_id: party , user_id: user_id}
      }}
      />)
  }
  //This is to handle when social login is pressed and we land again at login page and wait for auth signin event, 
  // till then we show blank page so that user should not click on social login buttons again.
  else if (window.sessionStorage.getItem('party_id') != null) 
  {
    return (<>
      <div>Please wait...</div>
      </>)
  }

  return (
    <>
      {user ? (
        <>
          <div>loading</div>
        </>
      ) : (
          <div className="login">
            <div className="login__inner">
              <h2 className="login__title">{user ? 'Welcome ' +JSON.stringify(user.attributes) : text[0].title}</h2>
              <Image
                src={ImageLogin}
                alt="PartyMeister Image IPhone"
                class="image__iphone"
              />
              <div className="button__container" style={!moreLogin ? { paddingTop: '16px',justifyContent: 'flex-end' } : { paddingTop: '16px', justifyContent: 'flex-start' } }>
                <div className="button__op_wrapper">
                  {!moreLogin ? (
                    <div className="button_op">
                      {android ? (
                        <Button
                            handler={() => {
                              window.sessionStorage.setItem('party_id', party);
                              Auth.federatedSignIn({ provider: 'Google'});
                            }
                            }
                            class="button__icon button__google"
                            label={<IconGoogle/>}
                        />
                        ) : (
                        <Button
                            handler={() => {
                              window.sessionStorage.setItem('party_id', party);
                              Auth.federatedSignIn({ provider: 'SignInWithApple'});
                            }
                            }
                            class="button__icon button__apple"
                            label={<IconApple/>}
                        />
                      )}
                      <Button
                        handler={toggle}
                        class="button__more"
                        label="More Login Options"
                      />  
                    </div>
                  ) : null}
                  
                  {moreLogin ? (
                    <div className="button__wrapper" style={moreLogin ? { paddingTop: '32px' } : null }>
                      <Button
                        handler={() => {
                          window.sessionStorage.setItem('party_id', party);
                          Auth.federatedSignIn({ provider: 'SignInWithApple'});
                        }
                        }
                        class="button__icon button__apple"
                        label={<IconApple/>}
                      />
                      <Button
                          handler={() => { 
                            window.sessionStorage.setItem('party_id', party);
                            Auth.federatedSignIn({ provider: 'Facebook'});
                          }
                        }
                          class="button__icon button__facebook"
                          label={<IconFacebook/>}
                      />
                      <Button
                          handler={() => {
                            window.sessionStorage.setItem('party_id', party);
                            Auth.federatedSignIn({ provider: 'Google'});
                          }
                          }
                          class="button__icon button__google"
                          label={<IconGoogle/>}
                      />
                    </div>
                  ) : null }
                
                </div>
              </div>
                
              <p className="login__text">By continuing you are indicating that you agree to our <a href="https://google.nl">Terms of Use</a> and <a href="https://google.nl">Privacy Policy</a></p>
            </div>
          </div>
      )}
    </>
  );
}

export default Login;
