import './Alert.css'
export default function Alert(props) {
  return (
      <>
        <div className="alert">
          <div className="alert__inner">
            <div className="alert__message">
              <h3 className="alert__title">{props.title}</h3>
              <p className="alert__body">{props.body}</p>
            </div>
          <div className="alert__btn-wrapper" style={props.showSecondBtn == true ? {justifyContent: 'space-between'} : {justifyContent: 'center'} }>
              <button
                className={props.labelSecond == false  ? "button__secondary alert__btn" : "button__ghost alert__btn"}
                onClick={props.handlerFirst}
              >
                {props.labelFirst}
              </button>
              
              {props.showSecondBtn == true ? (
                <button
                  className="button__secondary alert__btn"
                  onClick={props.handlerSecond}
                >
                  {props.labelSecond}
              </button>
              ) : null}
            </div>
          </div>
        </div>
        <div className="alert__overlay"></div>
      </>
    )
}