import axios from 'axios'
import {Auth} from 'aws-amplify'
import {fatal_log, error_log, warn_log, info_log, debug_log} from './Logger'

//var baseurl = 'https://4i6anfi774.execute-api.eu-west-1.amazonaws.com/dev';
var baseurl = 'https://m8mhjhlqrj.execute-api.eu-west-1.amazonaws.com/dev';
//var baseurl = 'https://dpaw0dp3a8.execute-api.eu-west-1.amazonaws.com/dev';

baseurl = "https://" + process.env.REACT_APP_BUILD + '.' + process.env.REACT_APP_REGION + '.pmapi.meisterapps-internal.com';

debug_log('baseurl ---> ' + baseurl);

export async function httpGetAuth(resource, queryStringObj, noAuth = false, error_logging = false){
  try {
    var token = "";
    
    if (noAuth == false) {
      const user = await Auth.currentAuthenticatedUser();
      token = user.signInUserSession.idToken.jwtToken;
      const session = user.signInUserSession;
      debug_log({session});
    }
    return await axios.get(baseurl+resource, {
      headers: {
        Authorization: token
      },
      params:queryStringObj
    });
  } catch (err) {
    if (error_logging) {
        error_log(resource + ' failed, error:' + err);
        if (err.response && err.response.data) {
          error_log(resource + ' failed, error:' + JSON.stringify(err.response.data));
        }
      }
      //Always return exact same error object received as it is being parsed by caller
      return new Promise((resolve,reject)=> reject(err));
  }
}

export async function httpPutAuth(resource, bodyObj, error_logging = false){

  try {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    debug_log({token});

    return await axios.put(baseurl+resource, bodyObj, {
      headers: {
        Authorization: token
      }    
    });
  } catch (err) {
    if (error_logging) {
      error_log(resource + ' failed, error:' + err);
      if (err.response && err.response.data) {
        error_log(resource + ' failed, error:' + JSON.stringify(err.response.data));
      }
    }
    //Always return exact same error object received as it is being parsed by caller
    return new Promise((resolve,reject)=> reject(err));
  }
}

export async function httpPostAuth(resource, bodyObj, error_logging = false){
  try {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    debug_log({token});

    return await axios.post(baseurl+resource,bodyObj, {
      headers: {
        Authorization: token
      } 
    });
  } catch (err) {
    if (error_logging) {
      error_log(resource + ' failed, error:' + err);
      if (err.response && err.response.data) {
        error_log(resource + ' failed, error:' + JSON.stringify(err.response.data));
      }
    }
    //Always return exact same error object received as it is being parsed by caller
    return new Promise((resolve,reject)=> reject(err));
  }
}

export async function httpDeleteAuth(resource, bodyObj){

  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  debug_log({token});

  return await axios.delete(baseurl+resource,{data:bodyObj}, {
    headers: {
      Authorization: token
    } 
  });
}