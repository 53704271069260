import axios from 'axios'

const LOG_FATAL = 0;
const LOG_ERROR = 1;
const LOG_WARN = 2;
const LOG_INFO = 3;
const LOG_DEBUG = 4;

var g_party_id = "";
var g_user_id = "";
var session_id = "";
var logurl = "https://brtnv07cf7.execute-api.eu-west-1.amazonaws.com/dev/logstring?application=partymeisterwebapp";
export function setPartyForLogging(party_id)
{
  g_party_id = party_id;
  session_id = g_party_id + '_' + g_user_id;
}

export function setUserForLogging(user_id)
{
  g_user_id = user_id;
  session_id = g_party_id + '_' + g_user_id;
}

async function logstring(level, str)
{
  var levelstr = "DEBUG";
  switch (level)
  {
    case LOG_FATAL:
      levelstr = "FATAL";
      break;
    case LOG_ERROR:
      levelstr = "ERROR";
      break;
    case LOG_WARN:
      levelstr = "WARN";
      break;
    case LOG_INFO:
      levelstr = "INFO";
      break;
    case LOG_DEBUG:
      levelstr = "DEBUG";
      break;
  }
  
  var queryStringObj = {sessionid: session_id, event_type:'LOG', log_level: levelstr, logtext: str};
  
  //TODO: For now we log only ERROR/FATAL logs to s3 to avoid unneccesary overload on logging
  //we need to improve logging API to support bulk logging
  if (level <= LOG_ERROR) {
    try {
      await axios.get(logurl, {
        params:queryStringObj
      });
    } catch (err) {
        console.log('logstring failed, error:' + err);
        if (err.response && err.response.data) {
          console.log('logstring failed, error:' + JSON.stringify(err.response.data));
        }
    }
  }
  console.log(str);
}


export function fatal_log(str) {
  //console.log('g_party_id: ' + g_party_id + ' g_user_id:' + g_user_id);
  if (process.env.REACT_APP_LOG_LEVEL<LOG_FATAL) return;
  
  logstring(LOG_FATAL, str);
};

export function error_log(str) { 
  //console.log('g_party_id: ' + g_party_id + ' g_user_id:' + g_user_id);
  if (process.env.REACT_APP_LOG_LEVEL<LOG_ERROR) return;
  logstring(LOG_ERROR, str);
};

export function warn_log(str) { 
  //console.log('g_party_id: ' + g_party_id + ' g_user_id:' + g_user_id);
  if (process.env.REACT_APP_LOG_LEVEL<LOG_WARN) return;
  logstring(LOG_WARN, str);
};

export function info_log(str) { 
  //console.log('g_party_id: ' + g_party_id + ' g_user_id:' + g_user_id);
  if (process.env.REACT_APP_LOG_LEVEL<LOG_INFO) return;
  logstring(LOG_INFO, str);
};

export function debug_log(str) { 
  //console.log('g_party_id: ' + g_party_id + ' g_user_id:' + g_user_id);
  if (process.env.REACT_APP_LOG_LEVEL<LOG_DEBUG) return;
  logstring(LOG_DEBUG, str);
};







