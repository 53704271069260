export default function PartyMeisterLogo() {
    return(
        <svg width="41" height="49" viewBox="0 0 41 49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.11963 17.4432L12.916 23.8497L15.0515 18.3584L7.72977 16.833" stroke="#FFB400" strokeWidth="2.44057"/>
            <circle cx="4.98414" cy="15.613" r="3.35579" stroke="#FF3066" strokeWidth="2.44057"/>
            <path d="M33.356 17.4432L27.5596 23.8497L25.4241 18.3584L32.7458 16.833" stroke="#2E2342" strokeWidth="2.44057"/>
            <circle r="3.35579" transform="matrix(-1 0 0 1 35.4915 15.613)" stroke="#FF3066" strokeWidth="2.44057"/>
            <path d="M13.1382 23.4292L18.4281 29.5543L10.0757 32.3385" stroke="#2E2342" strokeWidth="2.44057"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M21.7048 20.8419L26.0345 19.526L25.3248 17.1909L16.9399 19.7393L28.8631 30.8442L29.1807 28.9681L21.7048 20.8419Z" fill="#FFB400"/>
            <path d="M7.01318 39.856C10.3542 41.4336 24.2749 44.8674 33.4626 39.856" stroke="#2E2342" strokeWidth="2.44057"/>
            <path d="M19.1973 37.1427L8.47678 35.2666L5.93066 43.776L19.8673 47.5282L34.005 44.312L31.0568 35.2666L19.1973 37.1427Z" stroke="#2E2342" strokeWidth="0.134006"/>
            <path d="M18.1497 10.0654L9.01219 35.4012M22.0475 10.0654L31.4853 35.6525M9.01219 35.4012L5.89941 44.0321C10.5397 46.6306 22.7714 50.2686 34.5762 44.0321L31.4853 35.6525M9.01219 35.4012C12.4104 36.7752 21.6625 38.749 31.4853 35.6525" stroke="#FF3066" strokeWidth="2.44057"/>
            <circle cx="20.0983" cy="6.44596" r="4.56127" stroke="#FFB400" strokeWidth="2.44057"/>
        </svg>
    )
}