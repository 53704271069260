import Button from '../../components/Button/Button'
import Image from '../../components/Image/Image'
import './Guest.css'
import {useHistory, Redirect} from 'react-router-dom'
import React, { useEffect, useState , useRef} from 'react'
import {Auth, Storage} from 'aws-amplify'
// import Amplify, { API, Auth, Hub , Storage} from 'aws-amplify'
import PhotoUpload from '../PhotoUpload/PhotoUpload'
import '../PhotoUpload/PhotoUpload.css'
// import ImageUpload from "../../data/fileUpload"
import moment from 'moment'
import ImageTemp from '../../assets/images/prt_temp.jpg'
import ImageLoading from '../../assets/images/prt_loading.gif'
import {httpGetAuth, httpPostAuth, httpPutAuth, httpDeleteAuth} from '../../common/HTTP'
import Alert from '../../components/Alert/Alert'
import {fatal_log, error_log, warn_log, info_log, debug_log} from '../../common/Logger'

var states = {
    UPLOAD_SUCCESS: 'upload-success',
    UPLOAD_FAILED: 'upload-failed',
    PARTY_ENDED: 'party-ended',
    FILES_LIMIT_REACHED: 'files-limit-reached',
    FILE_NOT_SUPPORTED: 'file-not-supported'
}

var text = [
    { title: 'Party Name' },
    { title: 'Welcome ' },
    { paragraph: 'Guest' },
    { label: 'Upload Photo' },
    { label: 'Logout' }
]

const photouploadtext = [
    { title: 'Photo Upload' },
    { label: 'Retake' },
    { label: 'Upload', stateLabel: 'Uploading' }
]

// async function getUser() {
//   const session = await Auth.currentSession();
//   //debug_log('@@@@: ' + session.accessToken.jwtToken);
//   try {
//     const userData = await Auth.currentAuthenticatedUser();
//     //debug_log(userData);
//     return userData;
//   } catch (e) {
//     return error_log('Not signed in');
//   }
// }

export default function Guest(data) {
  
    const history = useHistory();

    // const [user, setUserDetails] = useState(null)
    const [filesToUpload, setFiles] = useState('')
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState('guest-page')
    
    const inputFile = useRef(null);
    

    var party_id;
    var party_name = "";
        
    var party_details = data.history.location.state.party_details;
    var user_details = data.history.location.state.user_details;
    
    text[1].title = 'Hi ' + user_details.first_name;
    
    try {
      party_id = party_details.party_id;
      party_name = party_details.p_name;
      text[0].title = party_name;
  } catch(err) {
    error_log('party details not found');
    history.push("/");
  }
  
  useEffect(() => {

  }, [])
  
  // var user = await getUser();
  // debug_log('@@@@@@@@@@' + JSON.stringify(user));
  
  info_log('Guest: party_id : ' + party_id + ' party name:' + party_name);

  const handleSelectedFile = e => {
    debug_log('@@@@@@@@@@' + e.target.files);
    
    // Array.from(e.target.files).forEach(file => {
    //   debug_log(file);      
    // });
        
    const { files } = e.target;
    if (files && files.length) {
      // const filename = files[0].name;
      // 
      // var parts = filename.split(".");
      // const fileType = parts[parts.length - 1];
      // debug_log("fileType", fileType); //ex: zip, rar, jpg, svg etc.

      const fileListArr = Array.from(files);
      debug_log(fileListArr);
      
      const file = e.target.files[0];

        if (file.type == 'image/jpeg' || file.type == 'image/png') 
        {
          console.log(file.type);
          setFiles(fileListArr);
      	  uploadHandler(fileListArr);
          
        } else {
          console.log('file type is not image: ' + file.type);
          setState(states.FILE_NOT_SUPPORTED);  
        }
    
    
      //setState('view-selected-page');
      // history.push("/PhotoUpload", { file: files[0] });
      
      
    }
  };
  
  async function uploadFile(fileToUpload, provider, user_name)
  {
    var fileName = fileToUpload.name;
    debug_log('uploadFile name:' + fileName + ' provider:' + provider + ' user:' + user_name);
    
    if (user_name == undefined)
      user_name = "";

    var time = moment().utc().format("YYYYMMDDHHmmss");
    fileName = time + '_' + provider + '_'+  fileName;

    var s3_filename = "org/" + party_id + "/"  + fileName;
    try {
      //add database entry first
      var res = await httpPutAuth('/uploadfile', {party_id: party_id, file_id: fileName, user_id: provider, user_name: user_name}, false);
      
      info_log('db entry success');
      try {
        //upload to s3
        var result = await Storage.put(s3_filename, fileToUpload, {
          level: 'public',
          contentType: 'image/jpeg'
        });
        
        info_log('s3 upload success ' + result);  
        //setState('upload-success');
        return states.UPLOAD_SUCCESS;
        
      } catch (err) { //upload to s3 error
        
        error_log('s3 upload failed ' + err);
        //setState('upload-failed');
        //TODO: delete database entry=============
        try { //remove db entry in case of error
          var result = await httpPostAuth('/removefiles', {party_id: party_id, files:[{file_id:fileName}] }, false );
          info_log('deleted file entry: ' + fileName);
        } catch (err) {
          error_log('file entry delete failed' + err);
        }
        return states.UPLOAD_FAILED;
      }
  } catch (err) { //database entry failed
      //setLoading(false);
      //setState('upload-failed');
      if (err.response) {
        
        if (err.response.data.message == 'PARTY_ENDED')
        {
          //alert('Host has ended the party!');
          //setState('party-ended');
          return states.PARTY_ENDED;
        }
        else if (err.response.data.message == 'FILES_LIMIT_REACHED')
        {
            //alert('Files limit!');
            //setState('files-limit-reached');
            return states.FILES_LIMIT_REACHED;
        } else {
          error_log('data:' + JSON.stringify(err.response.data));
          error_log('status:' + err.response.status);
          return states.UPLOAD_FAILED;
        }
      } else {
        error_log('db entry failed: ' + err);
      }
    }
  }
  
    async function uploadHandler(filesToUpload) { 
        info_log('uploaded clicked' + typeof(filesToUpload));
        
        setLoading(true);
        
        for (let i = filesToUpload.length-1; i >=0 ; i--) {
          const file = filesToUpload[i];
          debug_log('fileToUpload: ' + file.name);
          
          if (file && file.name !== "")
          {
            var ret = await uploadFile(file, user_details.user_id, user_details.first_name);
            debug_log(ret);
            if (ret == states.UPLOAD_FAILED || ret == states.FILES_LIMIT_REACHED || ret == states.PARTY_ENDED)
            {
              break;
            }
            else {
              debug_log('length: ' + filesToUpload.length );
              if (filesToUpload.length>0)
                filesToUpload.splice(i,1) //remove files that is successfully uploaded
                
            }
          }
        }
        debug_log('length after upload: ' + filesToUpload.length );
        setState(ret);
        setLoading(false);
    }
    
    function logoutHandler() {
        debug_log('logout clicked');
        Auth.signOut({global: true});
    }

    function fileSelectHandler() {
      inputFile.current.click();
        debug_log('select file clicked');
    }
    
    if (state == 'guest-page') {
    return (
        <>
            <div className={`photoUpload__loading${!loading ? ' hidden' : ''}`}>
                <Image
                    src={ImageLoading}
                    alt="PartyMeister Uploading" 
                    class=""
                />
            </div>
            <div className="guest__header">
                <h1 className="guest__header-title">{text[0].title}</h1>
            </div>
            <div className="guest">
                <div className="guest__inner">
                    <div className="guest__wrapper">
                        <h2 className="guest__title">{text[1].title}</h2>
                    </div>
                    {process.env.REACT_APP_BUILD ==  "dev" && <input
                      style={{ display: "none" }}
                      accept=".jpg,.jpeg,.png"
                      ref={inputFile} multiple
                      onChange={handleSelectedFile}
                      type="file"
                    />} 
                    {process.env.REACT_APP_BUILD ==  "prod" && <input
                      style={{ display: "none" }}
                      accept=".jpg,.jpeg,.png"
                      ref={inputFile}
                      onChange={handleSelectedFile}
                      type="file"
                    />}
                  
                    <Button
                        handler={fileSelectHandler}
                        class="button__upload"
                        label={<span>{text[3].label}</span>}
                    />
                    <Button
                        handler={logoutHandler}
                        class="button__logout"
                        label={text[4].label}
                    />
                </div>
            </div>
        </>
    ) 
  } 
    // else if (state == 'view-selected-page')
  //     {
  //       debug_log(fileToUpload);
  //         return (
  //             <>
  //             <div className={`photoUpload__loading${!loading ? ' hidden' : ''}`}>
  //                 <Image
  //                     src={ImageLoading}
  //                     alt="PartyMeister Uploading" 
  //                     class=""
  //                 />
  //             </div>
  //             <div className="photoUpload">
  //                 <div className="photoUpload__inner">
  //                         <h2 className="photoUpload__title">{photouploadtext[0].title}</h2>
  //                     <Image
  //                         src={URL.createObjectURL(fileToUpload)}
  //                         alt="" // alt should be the party name.
  //                         class="image__full_width image__max_high--400px"
  //                     />
  //                     <div className="button__wrapper">
  //                         <input
  //                           style={{ display: "none" }}
  //                           accept=".jpg,.jpeg,.png"
  //                           ref={inputFile}
  //                           onChange={handleSelectedFile}
  //                           type="file"
  //                         />
  //                         <Button
  //                             handler={fileSelectHandler}
  //                             class="button__text button__ghost"
  //                             label={photouploadtext[1].label}
  //                         />
  //                         <Button
  //                             handler={uploadHandler}
  //                             class="button__text button__secondary"
  //                             label={!loading ? photouploadtext[2].label : text[2].stateLabel}
  //                         />
  //                     </div>
  //                 </div>
  //                 </div>
  //             </>
  //         )
  //     }
      else if (state == 'upload-success') {
        return <Alert
          title="Yay! Nice Photo"
          body="Your photo will soon be available on the big screen."
          handlerFirst={() => { 
            setState('guest-page');
           }
         }
          labelFirst="OK"
        />
      }
      else if (state == 'party-ended') {
        return <Alert
          title="Party Ended"
          body="The host has ended the party, you will be logged out as a guest."
          handlerFirst={() => { 
            Auth.signOut({ global: true });
            setState('redirect-main-page'); 
            
          }}
          labelFirst="OK"
        />
      }
      else if (state == 'files-limit-reached') {
        return <Alert
          title="Oops...Limit Reached"
          body="Sorry, your party seems to have hit the maximum photo upload limit. Please contact party host to upgrade for unlimited uploads."
          handlerFirst={() => { 
            setState('guest-page'); 
            
          }}
          labelFirst="OK"
        />
      }
      else if (state == states.FILE_NOT_SUPPORTED) {
        return <Alert
          title="Unsupported file"
          body="Sorry, This file is not supported. Please upload images only."
          handlerFirst={() => { 
            setState('guest-page'); 
            
          }}
          labelFirst="OK"
        />
      }
      else if (state == 'upload-failed') {
        return <Alert
          title="Oops!"
          body="Something went wrong."
          handlerFirst={() => { 
            setState('guest-page');   
          }}
          labelFirst="Cancel"
          showSecondBtn={true}
          handlerSecond={() => {  
            uploadHandler(filesToUpload);
          }}
          labelSecond="Retry"
        />
      }
      else if (state == 'redirect-main-page')
        return <Redirect to='/' />
  
}