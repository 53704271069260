import React, { useState } from 'react';
import Image from '../../components/Image/Image'
import Button from '../../components/Button/Button'
import './PhotoUpload.css'

import ImageTemp from '../../assets/images/prt_temp.jpg'
import ImageLoading from '../../assets/images/prt_loading.gif'
import {fatal_log, error_log, warn_log, info_log, debug_log} from '../../common/Logger'

export default function PhotoUpload(file) {
    const text = [
        { title: 'Photo Upload' },
        { label: 'Retake' },
        { label: 'Upload', stateLabel: 'Uploading' }
    ]
    // debug_log('###### Photo Upload:' + JSON.stringify(data.history.location.state.file));
    // ImageTemp = data.history.location.state.file;
    debug_log(JSON.stringify(file));
    const [loading, setLoading] = useState(false);
    
    function retakeHandler() {
        info_log('retake');
    }

    function uploadHandler() {
        setLoading(true);

        // simulating api call --> replace with api call
        setTimeout(() => {
            setLoading(false);
        },3000)
    }
    
    // async function uploadFile(fileToUpload, sub, user_name)
    // {
    //   debug_log('uploadFile' + sub + user_name);
    //   var fileName = fileToUpload.name;
    // 
    //   var time = moment().utc().format("YYYYMMDDHHmmss");
    //   fileName = time + '_' + sub + '_'+  fileName;
    // 
    //   var s3_filename = "org/" + party + "/"  + fileName;
    // 
    //   try {
    //     var result = await Storage.put(s3_filename, fileToUpload, {
    //       level: 'public',
    //       contentType: 'image/jpeg'
    //     });
    // 
    //     debug_log(result);
    //     debug_log('user name:' + user_name);
    // 
    //     const resp = await httpPutAuth('/uploadfile', {party_id: party, file_id: fileName, user_id: sub, user_name: user_name});
    //     debug_log('!!!!!!!!!!!!!!!!!!!' + '#####' + JSON.stringify(resp));
    // 
    //     return resp;
    //   }
    //   catch(err) {
    //   //TODO: clean up if something fails
    //   debug_log('failed to upload' + err);
    //     return err;
    //   }
    // }

    return (
        <>
        <div className={`photoUpload__loading${!loading ? ' hidden' : ''}`}>
            <Image
                src={ImageLoading}
                alt="PartyMeister Uploading" 
                class=""
            />
        </div>
        <div className="photoUpload">
            <div className="photoUpload__inner">
                    <h2 className="photoUpload__title">{text[0].title}</h2>
                <Image
                    src={ImageTemp}
                    alt="" // alt should be the party name.
                    class="image__full_width image__max_high--400px"
                />
                <div className="button__wrapper">
                    <Button
                        handler={retakeHandler}
                        class="button__text button__ghost"
                        label={text[1].label}
                    />
                    <Button
                        handler={uploadHandler}
                        class="button__text button__secondary"
                        label={!loading ? text[2].label : text[2].stateLabel}
                    />
                </div>
            </div>
            </div>
        </>
    )
}