import url from 'url'

import Image from '../../components/Image/Image'
import Button from '../../components/Button/Button'
import './QRScanner.css'

import ImageQr from '../../assets/images/prt_qr_example.jpg'

import {useEffect, useState } from 'react'
import {useHistory, Redirect} from 'react-router-dom'


import QrReader from 'react-webcam-qr-scanner'
import Login from '../Login/Login'
import {httpGetAuth, httpPostAuth, httpPutAuth, httpDeleteAuth} from '../../common/HTTP'
import Alert from '../../components/Alert/Alert'
import {fatal_log, error_log, warn_log, info_log, debug_log} from '../../common/Logger'

export default function QRScanner() {

    // window.sessionStorage.removeItem('party_id');
    const history = useHistory();
    info_log('Refresh QRScanner screen==============');
    
    var party = null;//window.sessionStorage.getItem('party_id');
    
    const [input, setInput] = useState({state: 'scan-qr', party_id: party});
    
    debug_log('party_id:' + input.party_id + '     ScanQR state:' + input.state);

    
    function qrcodeHandler(data) {
        if (data) {
          //debug_log(JSON.stringify(data));
        const queryObject = url.parse(data.data,true).query;
        info_log('query obj:' + JSON.stringify(queryObject));
        debug_log('inside qrcodeHandler: ' + input.state);
        
        if (party == null && queryObject.hasOwnProperty('party')) {
          party = queryObject.party;
          debug_log('party============================:' + queryObject.party);
          
          httpGetAuth('/getpartystatus', {party_id: queryObject.party}, true)
          .then( result => {
                  debug_log('state:' + input.state);
                    
                    if (!result.data || !result.data.hasOwnProperty('p_status')) {
                     error_log('result is not proper');
                     setInput({state:'party-not-found', party_id: null});
                   }
                    else if (result.data && result.data.p_status == "STARTED") {
                      if (result.data.guests_limit > -1 && result.data.guest_count >= result.data.guests_limit)
                        setInput({state:'guests-limit-reached', party_id: queryObject.party});
                      else
                        history.push("/Login", { party_id: queryObject.party });
                    }
                    else if (result.data && result.data.p_status == "ENDED") {
                      //alert('Can\'t Join, This Party is already ended!');
                      setInput({state:'party-ended', party_id: queryObject.party});
                    }
                    else {
                      error_log('result is not proper, else');
                      //alert('Can\'t Join, Unknown error!');
                      setInput({state:'party-not-found', party_id: queryObject.party});
                    }
                
          }).catch( err => {
              error_log('getpartystatus failed: ' + err);
                //alert('Can\'t Join, Unknown error!!');
                setInput({state:'party-not-found', party_id: queryObject.party});
          })
          
        }
      }
      
    }
    
    function handleError(err) {
      debug_log(err);
    }
    debug_log('party var:' + input.party_id);
    
    var facingMode = 'rear';
    return(
    <>
    
    {input.state === 'scan-qr' && 
      <div className="qr__wrapper">
          
        <div className="qr__line">
          <div className="qr__line--inner"/>
        </div>
        <svg className="qr__svg" height="100%" width="100%">
          <rect fill="rgba(0, 0, 0, 0.8)" x="0" y="0" mask="url(#mask)" height="100%" width="100%"/>
          <mask id="mask" maskUnits="userSpaceOnUse" maskContentUnits="userSpaceOnUse">
            <rect className="bg" x="0" y="0" fill="white" height="100%" width="100%" />
            <rect className="cutout" fill="black" height="300" width="300" x="50%" y="50%" transform="translate(-150,-150)" />
          </mask>
        </svg>
          
        <QrReader 
          onDecode={qrcodeHandler}
          onScannerLoad={handleError}
          constraints={{ 
            audio: false, 
            video: { 
              facingMode: "environment" 
            } 
          }}
          captureSize={{ width: 1280, height: 720 }}
        />
      </div>
    }
    {input.state === 'party-ended' && 
      <>
      return <Alert
        title="Party Ended"
        body="Sorry this party seems to be Ended!"
        handlerFirst={() => { setInput({state:'go-to-scan-page', party_id: null}); }}
        labelFirst="Cancel"
        showSecondBtn={true}
        handlerSecond={() => { setInput({state:'scan-qr', party_id: null}); }}
        labelSecond="Re-scan"
      />
      </>
    }
    {input.state === 'party-not-found' && 
      <>
      return <Alert
        title="Can't connect to Party"
        body="Oops..We can't find a party with the QR Code."
        handlerFirst={() => { setInput({state:'go-to-scan-page', party_id: null}); }}
        labelFirst="Cancel"
        showSecondBtn={true}
        handlerSecond={() => { setInput({state:'scan-qr', party_id: null}); }}
        labelSecond="Re-scan"
      />
      </>
    }
    {input.state === 'guests-limit-reached' && 
      <>
      return <Alert
        title="Oops...Limit Reached"
        body="Sorry, your party seems to have hit the maximum guest limit. Please contact party host to upgrade for unlimited guests."
        handlerFirst={() => { setInput({state:'go-to-scan-page', party_id: null}); }}
        labelFirst="OK"
      />
      </>
    }
    {input.state === 'go-to-scan-page' && 
      <>
        return <Redirect to='/' />
      </>
    }

    
    </>
    )

}
