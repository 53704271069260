import React, { useEffect, useState } from 'react'
import Button from '../../components/Button/Button'
import {useHistory} from 'react-router-dom'

import './Welcome.css'
import {Auth} from 'aws-amplify'

import IconArrowRight from '../../assets/icons/IconArrowRight/IconArrowRight'
import IconHouseWarming from '../../assets/images/HouseWarming/HouseWarming'

import {httpGetAuth, httpPostAuth, httpPutAuth} from '../../common/HTTP'
import {fatal_log, error_log, warn_log, info_log, debug_log} from '../../common/Logger'

var text = [
    { title: 'Welcome to ' },
    { paragraph: 'By ' },
    { paragraph: '' }
]
var party_id = null;
var user_id = null;

export default  function Welcome(data) {
  
  const history = useHistory();
  const [party_details, setPartyDetails] = useState(null)
  const [user_details, setUserDetails] = useState(null)
  
  try {
    party_id = data.history.location.state.party_id;
    user_id = data.history.location.state.user_id;
    // party = window.sessionStorage.getItem('party_id');
  } catch(err) {
  }

    useEffect(() => {
    async function getPartyDetails() {
      try {
        //var party_id = window.sessionStorage.getItem('party_id');
        info_log('Welcome Screen: party_id: ' + party_id + ' title: ' + text[0].title);
        window.sessionStorage.removeItem('party_id');

        var resp =  await httpGetAuth('/getparty', {party_id: party_id});
        debug_log(resp.data.p_name);
        text[0].title = 'Welcome to ' + resp.data.p_name;
        text[1].paragraph = 'By ' + resp.data.host_name;
        text[2].paragraph = resp.data.p_desc;
        setPartyDetails(resp.data);

        resp =  await httpGetAuth('/getuser', {party_id: party_id, user_id: user_id});
        setUserDetails(resp.data);
        
        debug_log(resp.data);
      } catch (err) {
        error_log('getparty or getuser failed: ' + err);
      }
    }

    getPartyDetails()
  }, [])
  
    function buttonHandler() {
        info_log('click');
       //  history.push({
       //     pathname: '/Guest',
       //     state: JSON.stringify({ party_id: party_id })
       // });
       info_log('p name :' + party_details.p_name);

       if (user_details.first_name == "")
        history.push("/UserInfo", { party_details: party_details, user_details: user_details });
       else
        history.push("/Guest", { party_details: party_details, user_details: user_details});
    }

    // const BackButtonListener = ({ children }) => {
    //   const [pressed, setPressed] = React.useState(false)
    //   React.useEffect(() => {
    //     window.onpopstate = e => {
    //       setPressed(true)
    //     };
    //   });
    //  return (
    //     <h3>Back button: {pressed.toString()}</h3>
    //   );
    // };
    return(
        <div className="welcome">
            <div className="welcome__inner">
                <IconHouseWarming />
                <div className="welcome__text--wrapper">
                    <h2 className="welcome__title">{text[0].title}</h2>
                    <p className="welcome__text">{text[1].paragraph}</p>
                </div>
                <p className="welcome__text">{text[2].paragraph}</p>
                
                <Button
                    handler={buttonHandler}
                    class="button__icon button__secondary"
                    label={<IconArrowRight/>}
                />
            </div>
        </div>
    )
}