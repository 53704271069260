import React, { useState } from 'react';
import Menu from '../../components/Menu/Menu'
import './Header.css'

import {BrowserRouter as Router, Switch, Route }  from 'react-router-dom';


import PartyMeisterLogo from '../../assets/icons/PartyMeisterLogo/PartyMeisterLogo';
export default function Header() {
  const [menuOpen, setMenuOpen] = useState(false);

  function clickHandler() {
    setMenuOpen(menuOpen => !menuOpen);
  }

  if (window.location.pathname === '/QRScanner') return null;
  return (
    <>
      <div className="header">
        <div className="header__inner">
          <div className="header__brand">
            <PartyMeisterLogo/>
          </div>
          <div onClick={ clickHandler } className={`header__hamburger${menuOpen ? ' header__hamburger--open' : ''}`}>
            <span className="header__hamburger--line"></span>
          </div>
        </div>
      </div>
      { menuOpen ? <Menu/> : null}
    </>
  );
}
