import React, { useEffect, useState , useRef} from 'react'
import Button from '../../components/Button/Button'
import {useHistory} from 'react-router-dom'
import TextField from "@material-ui/core/TextField";
import './UserInfo.css'
import {Auth} from 'aws-amplify'

import IconArrowRight from '../../assets/icons/IconArrowRight/IconArrowRight'

import {httpGetAuth, httpPostAuth, httpPutAuth} from '../../common/HTTP'
import {fatal_log, error_log, warn_log, info_log, debug_log} from '../../common/Logger'

var text = [
    { title: 'What should we call you?' },
    { paragraph: '' },
    { paragraph: '' }
] 

var inputEl = null;
var party_details = null;
var user_details = null;

export default  function UserInfo(data) {
  
  inputEl = useRef(null);
  const history = useHistory();
  const [user_name, setUserName] = useState(null)
  const [disableButton, SetDisableButton] = useState(true)
    
  try {
    debug_log(data);
    party_details = data.history.location.state.party_details;
    user_details = data.history.location.state.user_details;
    // debug_log('1party_id:' + party_id);
  } catch(err) {
    // debug_log('2party_id:' + party_id);
  }

    useEffect(() => {
    async function getPartyDetails() {
    }

    getPartyDetails()
  }, [])
  
    async function buttonHandler() {
        info_log('click : ' + inputEl.current.value);
        try {
          user_details.first_name = inputEl.current.value;
          debug_log(user_details.first_name);
          var res = await httpPutAuth('/updateuser', {party_id: party_details.party_id, user_id:user_details.user_id, first_name: user_details.first_name}, false);
          
          history.push("/Guest", { party_details: party_details, user_details: user_details});
      } catch (err) {
        error_log(err);
      }
    }

     function key_handler(e) {
      console.log(e.key);
      //hack to fix 403 error when press Enter on iOS keyboard. we prevent default action and call button Handler
      if (e.key === 'Enter')
      {
        e.preventDefault();
        buttonHandler();
      }
    }
    
    return(
        <div className="userinfo">
            <div className="userinfo__inner">
                <br/> 
                <div className="userinfo__text--wrapper">
                  <h2 className="userinfo__title">{text[0].title}</h2>
                  <form>
                        <input 
                          type="text" 
                          name="username" 
                          ref={inputEl}
                          placeholder="Your name"
                          onKeyDown={e => key_handler(e)}
                          onChange={(e) => {
                            if (e.target.value.length > 0) {
                              SetDisableButton(false)
                            } else {
                              SetDisableButton(true)
                            }
                          }}
                        /> 
                  </form>
          </div>

        
                <Button
                    handler={buttonHandler}
                    class={`button__icon button__secondary${disableButton === true ? ' button__disabled' : ''}`}
                    label={<IconArrowRight />}
                />
            </div>
        </div>
    )
}
