// Components
import Main from '../Main/Main'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'

// Views
import ScanQr from '../ScanQr/ScanQr'
import Login from '../Login/Login'
import Guest from '../Guest/Guest'
import UserInfo from '../UserInfo/UserInfo'
import PhotoUpload from '../PhotoUpload/PhotoUpload'
import QRScanner from '../QRScanner/QRScanner'
import Welcome from '../Welcome/Welcome'


import Alert from '../../components/Alert/Alert'

import {BrowserRouter as Router, Switch, Route }  from 'react-router-dom';
 import {fatal_log, error_log, warn_log, info_log, debug_log} from '../../common/Logger'
 
import './App.css'

import {useEffect, useState } from 'react'

export default function App() {
  
  const [user, setUser] = useState('');

    
    debug_log('Appp.js ===>' + user);
    info_log('Appp.js ===>' + process.env.REACT_APP_BUILD);
    info_log('Appp.js ===>' + process.env.REACT_APP_LOG_LEVEL);
    return(
      <Router>
      <div className="app">
        <Header />
          <Main>
            
          {/* <Alert
            title="Oeps!"
            body="Something went wrong!"
            handlerFirst={() => { alert('click left') }}
            labelFirst="Cancel"
            showSecondBtn={true}
            handlerSecond={() => { alert('click right') }}
            labelSecond="Retry"
          /> */}
            {/* <Alert
            title="Oeps!"
            body="Something went wrong!"
            handlerFirst={() => { alert('click left') }}
            labelFirst="Cancel"
            showSecondBtn={false}
          /> */}
          <Switch>
            <Route path="/" exact component={ScanQr}/>
            <Route path="/QRScanner" exact component={QRScanner}/>
            <Route path="/Login" exact component={Login}/>
            <Route path="/Welcome" exact component={Welcome}/>
            <Route path="/Guest" exact component={Guest}/>
            <Route path="/UserInfo" exact component={UserInfo}/>
            <Route path="/PhotoUpload" exact component={PhotoUpload}/>
          </Switch>
        </Main>
        <Footer />
      </div>
      </Router>
    )
}
